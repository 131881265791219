import * as React from 'react'
import { IconProps } from './types'

export const PlayButton = ({ title, ...rest }: IconProps) => (
  <svg width='55' height='55' viewBox='0 0 55 55' fill='none' xmlns='http://www.w3.org/2000/svg' {...rest}>
    <title>{title || ''}</title>
    <circle opacity='0.7' cx='27.5' cy='27.5' r='27.5' fill='#2D3340' />
    <g opacity='0.9'>
      <path
        d='M43.3854 25.4523L20.1631 13.8406C19.6263 13.5738 18.9898 13.6032 18.48 13.9185C17.9701 14.2337 17.6594 14.79 17.6582 15.3894V38.6117C17.6591 39.2111 17.9699 39.7675 18.4799 40.0826C18.9899 40.3977 19.6266 40.4267 20.1631 40.1593L43.3854 28.5487C43.9717 28.2554 44.342 27.6561 44.342 27.0005C44.342 26.345 43.9717 25.7456 43.3854 25.4523Z'
        fill='white'
      />
      <mask id='mask0_249_384' mask-type='alpha' maskUnits='userSpaceOnUse' x='17' y='13' width='28' height='28'>
        <path
          d='M43.3854 25.4523L20.1631 13.8406C19.6263 13.5738 18.9898 13.6032 18.48 13.9185C17.9701 14.2337 17.6594 14.79 17.6582 15.3894V38.6117C17.6591 39.2111 17.9699 39.7675 18.4799 40.0826C18.9899 40.3977 19.6266 40.4267 20.1631 40.1593L43.3854 28.5487C43.9717 28.2554 44.342 27.6561 44.342 27.0005C44.342 26.345 43.9717 25.7456 43.3854 25.4523Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_249_384)' />
    </g>
  </svg>
)
