import * as React from 'react'
import { Button, Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs'
import { FormattedMessage } from 'react-intl'
import { breakpoints, useBreakpoint } from '/fiweb/lib'
import { VideoCard } from '/fiweb/components'
import { VideoType } from './VideoSection'

const CardGrid = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    display: grid;
    grid-column-gap: ${spacing.s};
    grid-template-columns: repeat(3, 400px);
    grid-row-gap: ${spacing.s};
    @media (max-width: 1337px) {
      // Lol this is actually where is should break, happy coincidence!
      grid-template-columns: repeat(2, 400px);
    }
    @media (max-width: 905px) {
      grid-template-columns: repeat(1, 400px);
    }
    @media ${breakpoints.small} {
      grid-template-columns: repeat(1, 325px);
    }
  `}
`

const INDEX_INCREMENT = 3

interface Props {
  videos: VideoType[]
}

export const Videos = ({ videos }: Props) => {
  const { spacing } = useCustomTheme()
  const { small } = useBreakpoint()
  const [index, setIndex] = React.useState(0)
  const [visibleVideos, setVisibleVideos] = React.useState<VideoType[]>([])

  const viewMore = () => {
    const next = index + INDEX_INCREMENT >= videos.length ? videos.length : index + INDEX_INCREMENT
    setVisibleVideos([...visibleVideos, ...videos.slice(index, next)])
    setIndex(next)
  }

  React.useEffect(() => {
    if (visibleVideos.length < 3) {
      viewMore()
    }

    return () => {
      setVisibleVideos([])
      setIndex(0)
    }
  }, [])

  return (
    <Pane display='flex' flexDirection='column' alignItems='center' width='100%' maxWidth={1260} marginTop={spacing.l}>
      <CardGrid>
        {visibleVideos.map((video) => (
          <VideoCard
            key={video._id}
            description={video.description || ''}
            thumbnail={video.thumbnail || ''}
            date={video.timestamp ? dayjs(video.timestamp).format('DD.MM.YYYY') : ''}
            title={video.title || ''}
            url={video.url || ''}
            videoPaneProps={{
              width: small ? 325 : 400,
              height: small ? 184 : 225,
            }}
          />
        ))}
      </CardGrid>

      {index < videos.length && (
        <FormattedMessage id='statisticsPage.viewMoreVideos'>
          {(txt) => (
            <Button
              appearance='primary'
              size='small'
              border='1px solid #FFFFFF'
              borderColor='#FFFFFF'
              marginTop={spacing.m}
              onClick={viewMore}
            >
              {txt}
            </Button>
          )}
        </FormattedMessage>
      )}
    </Pane>
  )
}
