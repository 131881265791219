import * as React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import styled, { css } from 'styled-components'
import { TableauReport } from '/~/components'
import { useBreakpoint } from '/fiweb/lib'

const SectionedPane = styled(Pane)`
  ${({ theme: { spacing } }) => css`
    margin: 0 auto;
    width: 100%;
    max-width: 1260px;
    padding-bottom: ${spacing.l};
    padding-top: ${spacing.l};
  `}
`

interface HideToolbarPaneProps {
  backgroundColor: string
}

// Hacky way of hiding toolbar while we're publicly hosting the reports
const HideToolbarPane = ({ backgroundColor }: HideToolbarPaneProps) => {
  return (
    <Pane position='relative' width='100%' height={0}>
      <Pane position='absolute' width='100%' height={28} bottom={0} left={0} backgroundColor={backgroundColor} />
    </Pane>
  )
}

const CAPITAL_URL = 'https://public.tableau.com/views/Kapitalflyt/Kapitalflyt'
const FOLKEINVESTORER_URL = 'https://public.tableau.com/views/Folkeinvestorer/Folkeinvestoranalyse'
const CAMPAIGNS_URL = 'https://public.tableau.com/views/Kampanjer/Varmekartfolkeinvestorerbelp'

export const TableauSection = () => {
  const theme = useCustomTheme()
  const { small, medium } = useBreakpoint()

  return (
    <Pane width='100%' backgroundColor={theme.colors.fiDark10}>
      <SectionedPane paddingX={small ? theme.spacing.xs : theme.spacing.s}>
        <Heading size={600}>Kapital</Heading>
        <Paragraph size={small ? 300 : 400} maxWidth={840} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          Søk på hvorledes kapitalen flyter fra fylke til fylke for ønsket valgt tidsperiode.
        </Paragraph>

        <TableauReport report='capital' url={CAPITAL_URL} />
        <HideToolbarPane backgroundColor={theme.colors.fiDark10} />
      </SectionedPane>

      <Pane width='100%' backgroundColor={theme.colors.fiDark}>
        <SectionedPane paddingX={small ? theme.spacing.xs : theme.spacing.s}>
          <Heading size={600} color={theme.colors.fiDark10}>
            Folkeinvestorer
          </Heading>
          <Paragraph
            size={small ? 300 : 400}
            maxWidth={750}
            color={theme.colors.fiDark10}
            marginBottom={medium ? theme.spacing.s : theme.spacing.m}
          >
            Disse visualiseringene består av et kart over hvor i landet investorer registrert på plattformen vår hører
            til. Videre så kan du se aldersdistribusjon blant registrerte investorer og en linjegraf som viser
            akkumulert antall registrerte investorer. I tillegg kan du se eksakt hvor mange unike investorer som har
            registrert en profil hos Folkeinvest.
          </Paragraph>

          <TableauReport report='folkeinvest' url={FOLKEINVESTORER_URL} />
          <HideToolbarPane backgroundColor={theme.colors.fiDark} />
        </SectionedPane>
      </Pane>

      <SectionedPane paddingX={small ? theme.spacing.xs : theme.spacing.s}>
        <Heading size={600}>Emisjoner</Heading>
        <Paragraph size={small ? 300 : 400} maxWidth={560} marginBottom={medium ? theme.spacing.s : theme.spacing.m}>
          Denne visualiseringen lar deg se alders- og kapitaldistribusjon for emisjoner som har vært på Folkeinvest.no
          for valgt tidsperiode.
        </Paragraph>

        <TableauReport report='campaigns' url={CAMPAIGNS_URL} />
        <HideToolbarPane backgroundColor={theme.colors.fiDark10} />
      </SectionedPane>
    </Pane>
  )
}
