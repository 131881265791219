import * as React from 'react'
import { Pane } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { GetStatisticsVideosQuery, useGetStatisticsVideosQuery } from '/~/types/graphql'
import { useBreakpoint } from '/fiweb/lib'
import { FeaturedVideo } from './FeaturedVideo'
import { Videos } from './Videos'

export type VideoType = GetStatisticsVideosQuery['getVideosBySection'][number]

export const VideoSection = () => {
  const theme = useCustomTheme()
  const { small } = useBreakpoint()
  const [videos, setVideos] = React.useState<VideoType[]>([])
  const [featuredVideo, setFeaturedVideo] = React.useState<VideoType>(null)
  const { data, loading } = useGetStatisticsVideosQuery()

  React.useEffect(() => {
    if (!loading && data?.getVideosBySection) {
      setVideos(data?.getVideosBySection?.filter((video) => !video.highlighted) || [])
      setFeaturedVideo(data?.getVideosBySection?.filter((video) => video.highlighted)[0] || null)
    }
  }, [loading])

  if (!data?.getVideosBySection?.length) {
    return null
  }

  return (
    <Pane
      display='flex'
      flexDirection='column'
      alignItems='center'
      width='100%'
      backgroundColor={theme.colors.fiDark}
      paddingX={small ? theme.spacing.xs : theme.spacing.s}
      paddingY={small ? theme.spacing.s : theme.spacing.l}
    >
      {featuredVideo && <FeaturedVideo video={featuredVideo} />}
      {videos.length > 0 ? <Videos videos={videos} /> : null}
    </Pane>
  )
}
