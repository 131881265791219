import * as React from 'react'
import tableau from 'tableau-api'
import { useBreakpoint } from '../fiweb/lib'

// NOTE: This is a bit hacky, but there's not much info available... Ole
declare global {
  interface Window {
    tableau: {
      Viz: (HTMLElement, string, any) => void
    }
  }
}

interface Props {
  report: string
  url: string
}

// Had to make one per report to be able to store it properly for dispose() and for them not to ovveride each other
const viz = {
  campaigns: null,
  capital: null,
  folkeinvest: null,
}

// NOTE this is one hacky component, but I did what I could with the time I had ¯\_(ツ)_/¯ - Ole
export const TableauReport = ({ report, url }: Props) => {
  const { small } = useBreakpoint()
  const ref = React.useRef<HTMLDivElement>()

  const initViz = () => {
    try {
      /*
       * Holy shit this is bad, but it's the only way I got it to work before the deadline...
       * We need to import and use 'tableau' to load it, and then we need a new instance
       * from window.tableau (NOT from the imported tableau).
       * I fucking hate Tableau - Ole
       */

      // @ts-ignore
      const _tableauInit = tableau

      // If it's already loaded, we need to reload it
      if (viz[report]) {
        viz[report].dispose()
        viz[report] = null
      }

      const options = {
        device: small ? 'phone' : 'desktop',
      }
      viz[report] = new window.tableau.Viz(ref.current, url, options)
    } catch (_e) {} // Silently throw errors, sssssh...
  }

  React.useEffect(() => {
    initViz()

    return () => {
      if (viz[report]) {
        viz[report].dispose()
      }
    }
  }, [])

  React.useEffect(() => {
    initViz() // Trigger re-load
  }, [small])

  return <div ref={ref} />
}
