import * as React from 'react'
import { Card, CardProps, Heading, Pane, PaneProps, Text } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { Video } from './Video'
import { Caption } from '/fiweb/components'

interface Props {
  description: string
  thumbnail: string
  date: string
  title: string
  url: string
  cardProps?: CardProps
  videoPaneProps?: PaneProps
}

export const VideoCard = ({ description, thumbnail, date, title, url, cardProps, videoPaneProps }: Props) => {
  const { colors, spacing } = useCustomTheme()

  return (
    <Card borderRadius='10px' background='white' overflow='hidden' {...cardProps}>
      <Video thumbnail={thumbnail} url={url} videoPaneProps={videoPaneProps} />
      <Pane width='100%' height={240} padding={spacing.s} overflow='hidden'>
        <Text size={300} fontWeight={600} color={colors.fiDark70}>
          {date}
        </Text>

        <Heading size={300}>{title}</Heading>

        <Caption color={colors.text.default}>{description}</Caption>
      </Pane>
    </Card>
  )
}
