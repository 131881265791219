import * as React from 'react'
import { Heading, Pane, Paragraph } from 'evergreen-ui'
import { useCustomTheme } from '/fiweb/lib/theme'
import { VideoType } from './VideoSection'

interface Props {
  video: VideoType
}

export const FeaturedVideo = ({ video }: Props) => {
  const { spacing } = useCustomTheme()

  return (
    <>
      <Pane width='100%' maxWidth={800} marginBottom={spacing.m}>
        <Heading size={600} color='white' textAlign='center'>
          {video.title}
        </Heading>

        <Paragraph color='white' textAlign='center'>
          {video.description}
        </Paragraph>
      </Pane>

      <Pane width='100%' maxWidth={1010}>
        <Pane position='relative' paddingBottom='56.25%' paddingTop='25' height={0}>
          <iframe
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            src={video.url}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </Pane>
      </Pane>
    </>
  )
}
